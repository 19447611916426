.term-service-main{
    padding-top: 3rem;
    color: rgb(90, 90, 90);
    font-size: 15px;
    font-weight: 400;
}
.term-service-main ul{
    margin-left: 35px;
}
.title-term-service{
    font-weight: 700;
    font-size: 16px;
}
.text-content{
    margin-bottom: 30px;
    color: rgb(38, 38, 38);
    font-size: 20px;
    font-weight: 700;
    line-height: 1.25;
}