.signin-btn {
    color: #fff;
    padding: 8px 30px;
    border-radius: 50px !important;
    border: none;
    background: linear-gradient(
    -45deg
    ,#f1406b,#f3ae5b);
    text-transform: uppercase;
}

.submit-btn {
    color: #fff;
    padding: 8px 30px;
    border-radius: 50px !important;
    border: none;
    background: linear-gradient(
    -45deg
    ,#f1406b,#f3ae5b);
    text-transform: uppercase;
}

.cancel-btn {
    background-color: rgb(150,150,150);
    border-radius: 100px;
}

.transparent {
    opacity: 0;
}

.inlineIcon {
    display: inline;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
}

.resultHolder {
    color: rgb(238,45,82);
    text-align: left;
    float: left;
    vertical-align: middle;
    transition: all 0.3s;
}

.float-right {
    float: right;
}

#main {
    transition: all 0.3s;
    background: rgb(238,45,82);
    background: linear-gradient(90deg, rgba(238,45,82,1) 0%, rgba(249,163,66,1) 100%);
}

.loginViaButton {
    box-shadow: 0px 5px 10px -5px rgba(0,0,0,0.5);
}

#signUpSuccessNotification {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 500px;
    width: 80vw;
    height: fit-content;
    border-radius: 10px;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    z-index: 10000;
    -webkit-box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 20px -5px rgba(0,0,0,0.75);
}

.blur {
    filter: blur(8px) grayscale(0.5);
    -webkit-filter: blur(8px) grayscale(0.5);
    pointer-events: none;
}

#backToSignIn {
    margin-top: 1rem;
}

#phase2Notification{
    text-align: center;
}