.footer {
    padding-top: 1px;
}
.content-footer {
    border-top: 1px solid #e7e7e9;
    margin-bottom: 20px;
    padding: 20px;
    margin-top: 45px;
    color: #222;
}
.logo {
    height: 28px;
    margin-bottom: 4px;
}
.copyRight {
    padding: 17px 0;
    text-align: center;
    font-size: 15px;
    background-color: #e7e7e9;
}
.link-footer{
    text-transform: inherit;
    font-size: 15px;
    font-weight: 600;
    padding: 0 1rem;
    color: #bcbcbc!important;
}

.link-footer:hover {
    color: #222 !important;
}
.icon-facebook{
    color: #bcbcbc!important;
    width: 25px;
}
.follow-text{
    text-transform: inherit;
    font-size: 15px;
    font-weight: 600;
    color: #bcbcbc!important;
}

@media only screen and (max-width: 768px){
    .top-footer{
        flex-direction: column;
    }
    .logo{
        margin-bottom: 20px;
    }
    .list-link{
        flex-direction: column;
    }
    .link-footer{
        padding: 0 !important;
    }
  }