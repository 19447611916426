.gradient-text {
    font-family: "Open Sans", "Sans Serif";
    font-weight: 700;
    background: -webkit-linear-gradient(#F89644, #F02D4E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.view-icon {
    height: 1rem;
    margin-right: 0.5rem;
}

.room-info-title{
    font-size: 1.5em;
    font-weight: 600;
}

.room-info-subtitle{
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 20em;
    align-self: flex-start;
}

.room-info-cost{
    font-size: 2em;
}

.room-info-gender{
    font-size: 2em;
}

.lessor-info-avatar{
    margin-bottom: 2em;
}

.share-btn{
    margin-top: 2em;
}

.image-gallery-custom-left-nav{
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    transition: all .3s ease-out;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
}

.image-gallery-custom-left-nav:hover{
    color: #F02D4E;
}

.image-gallery-custom-right-nav{
    right: 0;
    padding: 50px 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    transition: all .3s ease-out;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 2px 2px #1a1a1a);
}

.image-gallery-custom-right-nav:hover{
    color: #F02D4E;
}