.headerContainer {
    position: fixed;
    height: 50px;
    width: 100vw;
    z-index: 1000;
    background-color: #FFFFFF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
}

.headerLogo {
    height: 28px;
}
.MuiAppBar-colorDefault {
    background-color: #fff !important;
}
.MuiTypography-h6{
    font-size: 12px;
    font-weight: 700;
    color: #222;
}

.for-lessor-btn {
    border: none;
    color: #FFFFFF;
    padding: .375rem .75rem;
    font-weight: 700;
    background: linear-gradient(90deg, rgba(238,45,82,1) 0%, rgba(249,163,66,1) 100%);
    border-radius: 100px;
}

.signin-btn {
    color: #fff;
    padding: 9px 30px;
    border-radius: 50px;
    border: none;
    font-weight: 700;
    background: linear-gradient(
    -45deg
    ,#f1406b,#f3ae5b);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.MuiPaper-elevation4{
    box-shadow: rgb(0 0 0 / 17%) 0px 0px 6px 2px !important;
    box-shadow: none !important;
}

.headerUserMenuToogle {
    background: rgb(238,45,82);
    background: linear-gradient(90deg, rgba(238,45,82,1) 0%, rgba(249,163,66,1) 100%);
    padding: 5px;
    vertical-align: middle;
}

.dropdown-item {
    transition: all 0.2s;
}

.dropdown-item:active {
    background: rgb(238,45,82);
}

.dropdown-item:hover {
    background: #DDDDDD
}

.navLink {
    transition: all 0.2s;
    color: #bcbcbc!important;
    font-size: 12px;
    font-weight: 700;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
    text-transform: uppercase;
    margin: 0 !important;
    font-family: "Open Sans";
}

.navLink:hover {
    color: #222 !important;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

.nav-active {
    color: #222 !important;
}