.card {
    transition: all 0.2s;
}

.card-body {
    padding: 10px;
}

.gradient-text {
    font-family: "Open Sans", "Sans Serif";
    font-weight: 700;
    background: -webkit-linear-gradient(#F89644, #F02D4E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-price-container{
    background-color: #FFF;
    padding: 0 16px;
}

.card:hover {
    transform: scale(1.03);
}

.card-address-icon {
    margin-right: 1em;
    height: 1em;
    width: auto;
}

.card-img-top {
    width: 100%;
    height: 25vh;
    object-fit: cover;
}

.makeStyles-cardTitle-153 {
    font-size: 15px !important;
    font-family: 'Open Sans' !important;
}
.card-icon {
    height: 1rem;
}

.allowanceInfo {
    float: right;
}

.MuiGrid-grid-xs-3{
    max-width: 15% !important;
}

.genderAllowance .card-icon {
    height: 0.8rem;
}

.genderAllowance{
    margin-left: 5px;
    right: 0px;
    transform: scale(1.2);
}

.allowanceProp {
    font-size: 1.2em;
    display: inline;
}

.MuiCardContent-root:last-child{
    padding-bottom: 0 !important;
}
.MuiGridListTile-root{
    height: 'fit-content' !important;
}
.MuiPaper-outlined{
    border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    padding-top: 15px !important;
}
.MuiGridListTile-root{
    width: 100% !important;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 1;
    }
}
@media (min-width: 60rem) {
    .card-columns {
        column-count: 1;
   }
}
@media (min-width: 85rem) {
    .card-columns {
        column-count: 1;
   }
}
@media (min-width: 120rem) {
    .card-columns {
        column-count: 1;
    }
}