#view-container {
    position: relative;
    height: fit-content;
    min-height: 60vh;
    background-color: #ffffff;
    padding: 30px 0;
}

.close-view{
    position: absolute;
    right: 0;
    top: -7px;
    color: #565656;
    font-size: 20px;
}

.card-title {
    font-weight: 600;
    font-size: 1.5em;
    padding-bottom: 2em;
    margin-right: 10px;
    color: #000;
}

.card-header {
    padding-bottom: 0.75em;
}

.card-cost {
    font-weight: 600;
    font-size: 2em;
}

.gradient-text {
    font-family: "Open Sans", "Sans Serif";
    font-weight: 700;
    background: -webkit-linear-gradient(#F89644, #F02D4E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-currentSlot {
    font-size: 1.2em;
    display: inline;
}

.card-right {
    float: right;
}

.card-info {
    padding-bottom: 0.75em;
}

.view-icon {
    height: 1rem;
    margin-right: 0.5rem;
}

.card-group-title {
    font-weight: 600;
    font-size: 1rem;
    color: #070707;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #07070744;
}

img {
    vertical-align: middle !important;
}

.aws-btn {
    --slider-height-percentage: 60%;
    --slider-transition-duration: 400ms;
    --organic-arrow-thickness: 4px;
    --organic-arrow-border-radius: 0px;
    --organic-arrow-height: 40px;
    --organic-arrow-color: #26456f;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-background: transparent;
    --control-bullet-color: #2d5182;
    --control-bullet-active-color: #26456f;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
}

.image-gallery-fullscreen-button, .image-gallery-play-button {
    transform: scale(0.7);
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
    height: 3rem !important;
    width: 3rem !important;
}

.image-gallery-icon:hover {
    color: #F02D4E !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus {
    border-color: #F02D4E !important;
    border-width: 1px !important;
    border-radius: 3px;
}
.image-gallery-icon{
    filter: none!important;
    color: #bbbbbb !important;
}

.house-information{
    min-height: 40vh;
    margin: 0 1rem 2rem 1rem;
}
.button-review{
    color: #fff !important;
    background: linear-gradient(
        -45deg
        ,#e52e5a,#e29a43);
        margin-top: 2rem;
        border: none !important;
}
.makeStyles-reviewContainer-15{
    overflow: auto !important;
    height: 75vh !important;
    width: 100vh !important;
}
.makeStyles-reviewContainer-15::-webkit-scrollbar{
    display: none !important;
}
.MuiRating-icon-774, .MuiRating-label-166{
    margin-top: 4px !important;
}
.image-gallery-image{
    /* width: 100%; */
    /* object-fit: cover !important; */
}
.image-gallery-content{
    /* display: flex !important; */
}

.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: 100%;
    max-height: 400px !important;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}