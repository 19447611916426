@keyframes cf3FadeInOut {
    0% {
        opacity:1;
    }
  40% {
    opacity:0.8;
  }
  55% {
    opacity:0.7;
  }
  60% {
    opacity:0.5;
  }
  70% {
    opacity:0.3;
  }
  90% {
    opacity:0.1;
  }
  95% {
    opacity:0.05;
  }
  100% {
    opacity:0;
  }
}
  
#loadingScreen {
    animation-name: cf3FadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-direction: alternate;
}

#loadingScreen {
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: bisque;
    z-index: 100000 !important;
    display: block;

}

#loadingIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.home-page{
    font-family: 'Open Sans', sans-serif;
}
.banner{
    position: relative;
}

.box-image{
    width: fit-content;

}
.image-banner{
    width: 100%;
    object-fit: center;
}
.content-banner{
    position: absolute;
    font-size: 1em;
    top: 15%;
    left: 10rem;
    width: 40%;
    color: #000000;
    font-weight: 500;
}
.title-banner{
    font-family:'Open Sans';
    font-style: normal;
    font-weight: bold;
    line-height: 69px;
    
    color: #37373B;
}
.image-google{
    width: 150px;
}
.image-ios{
    width: 179px;
}

.item-main-content{
    margin-top: 2rem;
}
.line-title{
    width: 6rem;
    height: 3px;
    background: linear-gradient(90deg, rgba(238,45,82,1) 0%, rgba(249,163,66,1) 100%);
}
.title-main-content{
    font-style: normal;
    font-weight: 600;
    color: #222;
    font-size: 1.8rem;
    padding: 1.5rem 0;
}
.first-content{

}
.left-content{
    width: fit-content;
    overflow: hidden;
}
.left-content img{
    width: 100%;
    object-fit: cover;
}
.right-content{
    padding-left: 3rem;
}
.content-first{
    font-weight: 600;
    color: #222;
    font-size: 22px;
    line-height: 1.9rem;
    letter-spacing: 1.3px;
    margin-bottom: 2rem;
}
.icon-benifit{
    margin-top: 13px;
}
.item-benifit{
    margin: 0 0 1rem 1rem;
}
.title-item{
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    font-weight: 600;
    margin-bottom: 6px;
}
.normal-content{
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 600;
}
.button-experience{
    color: #fff;
    padding: 8px 30px;
    border-radius: 50px;
    border: none;
    background: linear-gradient(
    -45deg
    ,#f1406b,#f3ae5b);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}
.button-search{
    color: #fff;
    border-radius: 5px;
    border: none;
    background: linear-gradient(
    -45deg
    ,#f1406b,#f3ae5b);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: -50px;
    margin-left: 12px;
}
.button-experience:hover{
    color: #fff;
    background: linear-gradient(
        -45deg
        ,#e52e5a,#e29a43);
}
.gray-background{
    background: #EBEBEB;
    margin-top: 6rem;
    padding: 1rem 0;
}
.list-cards{
    margin: 3rem -15px;
}
.item-active{

}
.box-image-active, .box-image{
    width: fit-content;
    overflow: hidden;
}
.image-active-item, .image-bottom{
    object-fit:cover;
    width: 100%;
    margin-bottom: 1.5rem;
}
.title-bottom-item{
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
}
.footer {
    padding-top: 1px;
}

@media only screen and (max-width: 768px){
    .title-banner {
        line-height: 17px;
        font-size: 16px;
    }
    .content-banner {
        font-size: 10px;
        top: 15%;
        width: 70%;
        left: 3rem;
    }
    .button-search{
        display: none;
    }
    .button-store{
        display: none;
    }
    .title-main-content {
        font-size: 1rem;
    }
    .first-content{
        flex-direction: column;
    }
    .list-cards{
        flex-direction: column;
    }
  }