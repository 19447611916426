body {
  margin: 0;
  font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: none !important;
}

.gradientBackground {
  background: rgb(238, 45, 82);
  background: linear-gradient(90deg, rgba(238, 45, 82, 1) 0%, rgba(249, 163, 66, 1) 100%);
}

.gradient-text {
  font-family: "Open Sans", "Sans Serif";
  font-weight: 700;
  background: -webkit-linear-gradient(#F89644, #F02D4E);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blur {
  filter: blur(8px) grayscale(0.5);
  -webkit-filter: blur(8px) grayscale(0.5);
  pointer-events: none;
}
