#mainContainer {
    position: fixed;
    top: 64px;
    width: 100vw;
    height: calc(100vh - 64px);
    margin: 0px;
}

#slihomeMap {
    margin: 0;
    padding: 0;
}

#mapContainer {
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 64px);
}

.currentLocationButton {
    background: rgb(238, 45, 82);
    background: linear-gradient(90deg, rgba(238, 45, 82, 1) 0%, rgba(249, 163, 66, 1) 100%);
    color: #FFFFFF;
    margin-bottom: 0.65em !important;
}

.mapSearchBox {
    padding: 0.65em;
    margin-top: 0.65em;
    font-size: 15px;
    font-weight: 300;
    text-overflow: ellipsis;
    width: 400px;
}

.hidden {
    display: none;
}

#slihomeNearBy {
    position: fixed;
    display: flex;
    flex-flow: column;
    right: 0;
    height: calc(100vh - 64px);
    overflow: auto;
}

#filterContainer {
    border-radius: 5px;
    margin-bottom: 2px;
}

#toggleFilterPanelBtn {
    padding: 1rem 0.5rem 1rem 1rem;
    position: absolute;
    right: 0px;
    top: 50%;
    z-index: 100;
    background-color: #fff;
    border-start-start-radius: 50%;
    border-end-start-radius: 50%;
    transition: all 0.2s ease-in-out;
    box-shadow: -10px 0px 10px -10px rgba(0,0,0,0.5);
}

#toggleFilterPanelBtn:hover {
    background-color: #ddd;
    cursor: pointer;
}

#controlContainer {
    width: 100%;
    flex: 0 1 auto;
}

#resultContainer {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: scroll;
}

#viewList {
    top: 0px;
    left: 0px;
    width: 100%;
}

#loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    z-index: 10000;
}

#applyFilter {
    height: 100%;
    width: 100%;
    background: linear-gradient( 
        -45deg
         ,#f1406b,#f3ae5b);
    color: #FFFFFF;
}

.tag-holder {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px transparent;
    border-radius: 5px;
    background: #fee7eb;
    color: #f02d4e;
    transition: all 0.2s;
    font-size: inherit;
    line-height: inherit;
}

.react-tags {
    position: relative;
    padding: 0 10px;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    font-family: 'Open Sans';
    /* shared font styles */
    font-size: 1em;
    line-height: 1.5;

    /* clicking anywhere will focus the input */
    cursor: text;
    width: 100%;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}

.react-tags__selected {
    display: inline;
}

.react-tags__selected-tag {
    display: inline-block;
    box-sizing: border-box;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    border: 1px transparent;
    border-radius: 5px;
    background: #fee7eb;
    color: #f02d4e;
    transition: all 0.2s;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__selected-tag-name {
    font-weight: 800;
    background: -webkit-linear-gradient(#F89644, #F02D4E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.react-tags__selected-tag::after {
    color: #f02d4e;
    transition: all 0.2s;
    margin-left: 0;
    content: '';
}

@keyframes tagHover {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 8px;
        content: '\2715';
    }
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border: 1px solid #f02d4e
}

.react-tags__selected-tag:hover::after,
.react-tags__selected-tag:focus::after {
    margin-left: 8px;
    content: '\2715';
    animation: tagHover 0.2s linear;
}

.react-tags__search {
    display: inline-block;

    /* match tag layout */
    padding: 7px 2px;

    /* prevent autoresize overflowing the container */
    max-width: 100%;
}

@media screen and (min-width: 30em) {

    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }

}

.react-tags__search-input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    min-width: fit-content;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10000;
}

@media screen and (min-width: 30em) {

    .react-tags__suggestions {
        width: 240px;
    }

}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

li span img {
    vertical-align: unset !important;
}

label {
    margin-bottom: 0 !important;
}

.gm-style-mtc{
    font-family: 'Open Sans';
}

.gm-style-mtc button{
    font-family: 'Open Sans' !important;
    font-size: 15px !important;
}
.gm-style-mtc label{
    font-family: 'Open Sans' !important;
    font-size: 15px !important;
    margin-left: 5px;
}
.MuiPaper-elevation1{
    box-shadow: none !important;
}
.MuiGrid-grid-xs-12{
    font-size: 15px;
    font-family: 'Open Sans';
    margin-bottom: 6px !important;
}
.MuiAccordionSummary-content{
    margin: 0 !important;
}
.MuiInputBase-input{
    font-size: 15px !important;
    font-family: 'Open Sans' !important;
}
.MuiFormControlLabel-label{
    font-size: 15px !important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded{
    margin-top: -20px;
}
.gm-ui-hover-effect img{
    width: 25px !important;
    height: 25px !important;
    right: 20px;

}
.gm-ui-hover-effect{
    opacity: 0 !important;

}
.image-gallery-index{
    left: 0 !important; 
    right: auto !important;
}
.content-window{
    display: flex;
    position: relative;
    width: 123px;
    height: 43px;
    padding: 16px 16px 0 12px;
}
.icon-button{
    padding: 0;
    position: absolute;
    top: 0px;
    right: 4px;
    color: #565656;
}
.icon-button:focus{
    outline: none !important;
    box-shadow: none;
}
.gm-style-iw-c{
    padding: 0 !important;
}
.gm-style-iw-d::-webkit-scrollbar{
    width: 0 !important;
    height: 0 !important;
}